// import { fetchCartItems } from '@/app/lib/data';
import { CartItem, Course, ItemStock } from '@/app/lib/definitions';
import ls from 'localstorage-slim';
import { create } from 'zustand';
import { createJSONStorage, persist, StateStorage } from 'zustand/middleware';

// Types
export type CartState = {
  // userId: string | null;
  cartItems: CartItem[];
  totalQuantity: number;
  totalPrice: number;
};

export type CartActions = {
  // validateCart: (sessionUserId: string | undefined) => void;
  getItemQuantity: (itemToGet: CartItem) => number | undefined;
  addToCart: (payload: FormData) => void;
  // addToCartTest: (payload: string) => void;
  removeFromCart: (itemToRemove: CartItem) => void;
  modifyQuantity: (itemToModify: CartItem, markup: 1 | -1) => void;
  syncStock: (itemStocks: ItemStock[]) => void;
  clearCart: () => void;
};

export type CartStore = CartState & CartActions;

// States
export const defaultInitState: CartState = {
  // userId: null,
  // loading: false,
  cartItems: [],
  totalQuantity: 0,
  totalPrice: 0,
};

// export const initCartStore = (): CartState => {
//   return { numbers: [1, 2, 3] };
// };

// Create
const secureStorage: StateStorage = {
  getItem: (name: string): string | null => {
    ls.config.storage = sessionStorage;
    return ls.get(name, { decrypt: true }) || null;
  },
  setItem: (name: string, value: string): void => {
    ls.config.storage = sessionStorage;
    ls.set(name, value, { encrypt: true });
  },
  removeItem: (name: string): void => {
    ls.config.storage = sessionStorage;
    ls.remove(name);
  },
};

export const createCartStore = (initState: CartState = defaultInitState) => {
  return create<CartStore>()(
    persist(
      (set, get) => ({
        ...initState,
        // validateCart: (sessionUserId: string | undefined) => {
        //   const storageUserId = get().userId;

        //   if (
        //     !sessionUserId ||
        //     !storageUserId ||
        //     storageUserId !== sessionUserId
        //   ) {
        //     set({
        //       userId: sessionUserId || null,
        //       cartItems: [],
        //       totalQuantity: 0,
        //       totalPrice: 0,
        //     });
        //   }
        // },
        getItemQuantity: (itemToGet: CartItem) => {
          const target = get().cartItems.find((cartItem) =>
            isSameCartItem(cartItem, itemToGet),
          );

          return target?.quantity;
        },
        addToCart: (payload: FormData) => {
          const newItem = newCartItem(payload);
          // check if the item is valid before proceeding
          if (!newItem) return;

          const newItemQuantity = parseInt(payload.get('quantity') as string);
          const newItemPrice = parseInt(payload.get('price') as string);

          const existSameItem = get().cartItems.find((cartItem) =>
            isSameCartItem(cartItem, newItem),
          );

          if (existSameItem) {
            set({
              cartItems: get().cartItems.map((cartItem) => {
                if (isSameCartItem(cartItem, newItem)) {
                  return {
                    ...cartItem,
                    quantity: cartItem.quantity + newItemQuantity,
                  };
                }
                return cartItem;
              }),
              totalQuantity: get().totalQuantity + newItemQuantity,
              totalPrice: get().totalPrice + newItemPrice * newItemQuantity,
            });
          } else {
            set({
              // cartItems: [...get().cartItems, newCartItem(payload)],
              cartItems: [...get().cartItems, newItem],
              totalQuantity: get().totalQuantity + newItemQuantity,
              totalPrice: get().totalPrice + newItemPrice * newItemQuantity,
            });
          }
        },
        // addToCartTest: (payload: string) =>
        //   set({
        //     cartItems: [newCartItemTest(payload), ...get().cartItems],
        //   }),
        removeFromCart: (itemToRemove: CartItem) => {
          // TODO: check if this additional check can reduce racing problem
          const target = get().cartItems.find((cartItem) =>
            isSameCartItem(cartItem, itemToRemove),
          );

          set({
            cartItems: get().cartItems.filter(
              (cartItem) => !isSameCartItem(cartItem, itemToRemove),
            ),
            totalQuantity: get().totalQuantity - target!.quantity,
            totalPrice: get().totalPrice - target!.price * target!.quantity,
          });
        },
        // TODO: determine whether it is better to use markup, or just final quantity
        modifyQuantity: (itemToModify: CartItem, markup: 1 | -1) => {
          // TODO: check if this additional check can reduce racing problem
          const target = get().cartItems.find((cartItem) =>
            isSameCartItem(cartItem, itemToModify),
          );
          // console.log(itemToModify);
          // console.log(target);
          if (!target) return;

          const targetQuantity = target.quantity;
          const targetPrice = target.price;

          if (
            (targetQuantity === 1 && markup === -1) ||
            (targetQuantity === 5 && markup === 1)
          ) {
            return;
          } else {
            const newTargetQuantity = target.quantity + markup;
            const newTotalQuantity = get().totalQuantity + markup;
            const newTotalPrice = get().totalPrice + targetPrice * markup;

            set({
              cartItems: get().cartItems.map((cartItem) => {
                if (isSameCartItem(cartItem, itemToModify)) {
                  // console.log(cartItem);
                  return {
                    ...cartItem,
                    quantity: newTargetQuantity,
                  };
                }
                return cartItem;
              }),
              totalQuantity: newTotalQuantity,
              totalPrice: newTotalPrice,
            });
          }
        },
        syncStock: (itemStocks: ItemStock[]) => {
          // set({
          //   cartItems: get().cartItems.map((cartItem) => {
          //     const stock = itemStocks.find(
          //       (itemStock) => itemStock.id === cartItem.id,
          //     )?.stock;

          //     if (stock !== cartItem.stock) {
          //       return {
          //         ...cartItem,
          //         stock,
          //       };
          //     }

          //     return cartItem;
          //   }),
          // });
          const updatedCartItems = get().cartItems.map((cartItem) => {
            const stock = itemStocks.find(
              (itemStock) => itemStock.id === cartItem.id,
            )?.stock;

            return {
              ...cartItem,
              stock,
            };
          });

          // Check if any cartItem has changed
          const hasChanged = updatedCartItems.some((updatedCartItem, index) => {
            return updatedCartItem.stock !== get().cartItems[index].stock;
          });

          if (hasChanged) {
            set({
              cartItems: updatedCartItems,
            });
          }
        },
        clearCart: () =>
          set({ cartItems: [], totalQuantity: 0, totalPrice: 0 }),
        // initNumbers: () => set({ cart: [1, 2, 3] }),
      }),
      {
        name: 'cart-store',
        storage: createJSONStorage(() => secureStorage),
        // skipHydration: true,
      },
    ),
  );
};

const isSameCartItem = (a: CartItem, b: CartItem) => {
  return (
    a.id === b.id &&
    a.size === b.size &&
    a.flavor === b.flavor &&
    a.noodleForm === b.noodleForm &&
    a.courses?.[0] === b.courses?.[0] &&
    a.courses?.[1] === b.courses?.[1]
  );
};

const newCartItem = (formData: FormData): CartItem | undefined => {
  // Fixed data
  const name = formData.get('name') as string;
  const id = formData.get('id') as string;
  const size = formData.get('size') as string | null;
  const flavor = formData.get('flavor') as string | null;

  // Flexible data
  const noodleForm = formData.get('noodleForm') as string | null;
  const course1 = formData.get('course1') as Course | null;
  const course2 = formData.get('course2') as Course | null;
  const quantity = parseInt(formData.get('quantity') as string);
  const price = parseInt(formData.get('price') as string);

  if (
    (course1 !== null && course2 !== null) ||
    (course1 === null && course2 === null)
  ) {
    const courses =
      course1 !== null && course2 !== null
        ? ([course1, course2].sort() as [Course, Course])
        : undefined;

    const newCartItem: CartItem = {
      id,
      name,
      size: size || undefined,
      flavor: flavor || undefined,
      noodleForm: noodleForm || undefined,
      courses,
      quantity,
      price,
    };

    console.log(newCartItem);

    return newCartItem;
  }

  return undefined;
};

// const newCartItemTest = (id: string) => {
//   // Fixed data
//   const newCartItem: CartItem = {
//     id,
//     name: 'Test',
//     quantity: 1,
//     price: 1,
//   };

//   return newCartItem;
// };
