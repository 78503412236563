'use client';

import { createContext, useContext, useRef, type ReactNode } from 'react';
import { useStore } from 'zustand';

import { createCartStore, type CartStore } from '@/app/stores/cart-store';

export const CartStoreContext = createContext<ReturnType<
  typeof createCartStore
> | null>(null);

export interface CartStoreProviderProps {
  children: ReactNode;
}

// #region Hooks
export const useCartStore = <T,>(selector: (store: CartStore) => T): T => {
  const cartStoreContext = useContext(CartStoreContext);

  if (!cartStoreContext) {
    throw new Error(`useNumbersStore must be use within NumbersStoreProvider`);
  }

  return useStore(cartStoreContext, selector);
};

export const useCartStoreInstance = (): ReturnType<typeof createCartStore> => {
  const cartStoreContext = useContext(CartStoreContext);

  if (!cartStoreContext) {
    throw new Error(`useNumbersStore must be use within NumbersStoreProvider`);
  }

  return cartStoreContext;
};
// #endregion

export const CartStoreProvider = ({ children }: CartStoreProviderProps) => {
  const storeRef = useRef<ReturnType<typeof createCartStore>>();
  if (!storeRef.current) {
    storeRef.current = createCartStore();
    // storeRef.current = createCartStore(initCartStore());
  }

  return (
    <CartStoreContext.Provider value={storeRef.current}>
      {children}
      {/* <CartStoreInitializer>{children}</CartStoreInitializer> */}
    </CartStoreContext.Provider>
  );
};

// export const CartStoreInitializer = async ({
//   children,
// }: {
//   children: React.ReactNode;
// }) => {
//   const validateCart = useCartStore((store) => store.validateCart);
//   const userId = await validateUserIdInSession();
//   validateCart(userId);

//   return <>{children}</>;
// };
